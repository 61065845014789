
import { defineComponent, PropType } from 'vue';
import { DeclareForm, DetailColunm } from './types/declareForm';
export default defineComponent({
  props: {
    data: Object as PropType<DeclareForm>,
  },
  setup(props) {
    const columnList: DetailColunm[] = [
      {
        label: '业务类型',
        prop: 'label',
        width: 180,
      },
      {
        label: '抬头类型',
        prop: 'consigneeTypeDesc',
        width: 100,
      },
      {
        label: '计费方式',
        prop: 'settleTypeDesc',
        width: 100,
      },
      {
        label: '贸易方式',
        prop: 'incotermTypeDesc',
        width: 100,
      },
      {
        label: '付款方式',
        prop: 'payTypeDesc',
        width: 100,
      },
      {
        label: '预付比例(%)',
        prop: 'ratioRate',
        width: 115,
      },
      {
        label: '付款时效',
        prop: 'payAgingDesc',
        width: 100,
      },
      {
        label: '船期',
        prop: 'shippingDate',
        width: 100,
      },
      {
        label: '目的港',
        prop: 'arrivalPort',
        width: 100,
      },
      {
        label: '客户',
        prop: 'customerName',
        width: null,
      },
      {
        label: '保证金比例(%)',
        prop: 'depositRate',
        width: 140,
      },
    ];
    const showCateagroy = () => {
      const label: string[] = [];
      if (props.data) {
        const { isNeedCg, isNeedJr, isNeedQg, isNeedHalfService } = props.data;
        if (isNeedQg) {
          label.push('代理');
        }
        if (isNeedCg) {
          label.push('代采');
        }
        if (isNeedJr) {
          label.push('垫资');
        }
        if (isNeedHalfService) {
          label.push('半期货');
        }
        return label.join('/');
      } else {
        return '-';
      }
    };
    return { columnList, showCateagroy };
  },
});
