
import { defineComponent, PropType } from 'vue';
import { DeclareForm, DetailColunm, ContractConfigVos } from './types/declareForm';
type BillingData = {
  label: string;
  currentFee: string | number | null | undefined; // 本次费用
  standardFee: string | number | null | undefined; // 标准费用
};
export default defineComponent({
  props: {
    data: Object as PropType<DeclareForm>,
  },
  setup(props) {
    // 初始化重新构建过数据展示格式
    const currentBillingVo = props.data && props.data.currentBillingVo ? props.data.currentBillingVo : null;
    const standardBillingVo = props.data && props.data.standardBillingVo ? props.data.standardBillingVo : null;
    const currentContractConfigVos = props.data && props.data.currentContractConfigVos ? props.data.currentContractConfigVos : [];
    const standardContractConfigVos = props.data && props.data.standardContractConfigVos ? props.data.standardContractConfigVos : [];
    // 柜型
    let containerType = () => props.data?.productVo?.containerType;
    const BillingVos: BillingData[] = [
      {
        label: (containerType as any) === 1 ? '小柜代理费' : '大柜代理费',
        currentFee: currentBillingVo && currentBillingVo.clearanceFee ? currentBillingVo.clearanceFee + currentBillingVo?.clearanceFeeLargeDesc : null,
        standardFee: standardBillingVo && standardBillingVo.clearanceFee ? standardBillingVo.clearanceFee + standardBillingVo?.clearanceFeeLargeDesc : null,
      },
    ];

    if (props.data?.isNeedCg) {
      BillingVos.push({
        label: '代采费',
        currentFee: currentBillingVo && currentBillingVo.agencyFee ? currentBillingVo?.agencyFee + currentBillingVo?.agencyFeeUnitDesc : null,
        standardFee: standardBillingVo && standardBillingVo.agencyFee ? standardBillingVo.agencyFee + standardBillingVo?.agencyFeeUnitDesc : null,
      });
    }

    //有半期货服务费
    if (props.data?.isNeedHalfService) {
      BillingVos.push({
        label: '半期货服务费',
        currentFee:
          currentBillingVo && currentBillingVo.halfQhdcServiceFee
            ? currentBillingVo.halfQhdcServiceFeeDesc + currentBillingVo?.halfQhdcServiceFeeUnitDesc
            : null,
        standardFee:
          standardBillingVo && standardBillingVo.halfQhdcServiceFee
            ? standardBillingVo.halfQhdcServiceFeeDesc + standardBillingVo?.halfQhdcServiceFeeUnitDesc
            : null,
      });
    }

    // 计费利率
    const getRate = (data: ContractConfigVos[], index: number) => {
      if (data) {
        const len = data.length;
        if (len >= index) {
          return data[index]?.rate + '%';
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
    const getDays = (data: ContractConfigVos) => {
      let str = '';
      const { configMin, configMax } = data;
      if (configMin && configMax) {
        str = configMin + '-' + configMax + '天';
      }
      if (configMin && !configMax) {
        str = configMin + '天以上';
      }

      return str;
    };
    const setContractConfigVo = (index): BillingData => {
      return {
        label: getDays(standardContractConfigVos[index]),
        currentFee: getRate(currentContractConfigVos, index),
        standardFee: getRate(standardContractConfigVos, index),
      };
    };
    const ContractConfigVos: BillingData[] = standardContractConfigVos.map((v, index) => {
      return setContractConfigVo(index);
    });
    // 计费利率
    const columnList: DetailColunm[] = [
      {
        label: '收费标准',
        prop: 'label',
        width: null,
      },
      {
        label: '本次收费',
        prop: 'label',
        width: null,
      },
      {
        label: '标准收费',
        prop: 'label',
        width: null,
      },
    ];

    return { columnList, BillingVos, ContractConfigVos };
  },
});
