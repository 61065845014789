import { filterName } from '@/utils/publicMethods';
import { defineComponent, PropType } from 'vue';
import { DetailColunm } from './types/declareForm';
export default defineComponent({
  props: {
    data: Object as PropType<any>,
  },
  setup(props) {
    const columnList: DetailColunm[] = [
      {
        label: '供应商',
        prop: 'supplierName',
        width: null,
      },
      {
        label: 'Epic供应商',
        prop: 'epicSupplierName',
        width: null,
      },
      {
        label: '供应商类型',
        prop: 'supplierType',
        width: null,
      },
      {
        label: '大类',
        prop: 'categoryDesc',
        width: null,
      },
      {
        label: '国家',
        prop: 'countryDesc',
        width: null,
      },
      {
        label: '厂号',
        prop: 'plantNo',
        width: null,
      },
    ];
    return () => {
      return (
        <>
          <div class="declare-title">
            <div>供应商信息</div>
          </div>
          <el-table data={[props.data]} class="crm-table__list detail-table" border>
            {columnList.map((v) => {
              return (
                <el-table-column prop={v.prop} show-overflow-tooltip={true} label={v.label} align="center">
                  {{
                    default: (scope) => filterName(scope.row[v.prop as string]),
                  }}
                </el-table-column>
              );
            })}
          </el-table>
        </>
      );
    };
  },
});
