
import { computed, defineComponent, PropType } from 'vue';
import { DeclareForm, DetailColunm } from './types/declareForm';
export default defineComponent({
  props: {
    data: Object as PropType<DeclareForm>,
  },
  setup(props) {
    const tradeCurrencyDesc = computed(() => {
      if (props.data && props.data.productVo) {
        return props.data?.productVo.tradeCurrencyDesc;
      } else {
        return '美元';
      }
    });

    const columnList: DetailColunm[] = [
      {
        label: '产品',
        prop: 'nameCn',
        width: null,
      },
      {
        label: `价格(${tradeCurrencyDesc.value}/吨)`,

        prop: 'unitPriceDesc',
        width: null,
      },
      {
        label: '重量(吨)',
        prop: 'weightDesc',
        width: null,
      },
    ];
    return { columnList, tradeCurrencyDesc };
  },
});
