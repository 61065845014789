
import { defineComponent, PropType } from 'vue';
import { DeclareForm, DetailColunm } from './types/declareForm';
export default defineComponent({
  props: {
    data: Object as PropType<DeclareForm>,
  },
  setup(props) {
    const columnList: DetailColunm[] = [
      {
        label: '创建人',
        prop: 'createName',
        width: null,
      },
      {
        label: '创建时间',
        prop: 'createdTime',
        width: null,
      },
      {
        label: '客户经理',
        prop: 'clientManager',
        width: null,
      },
      {
        label: '采购',
        prop: 'purchase',
        width: null,
      },
    ];
    // isNeedCg 是否代采
    let { isNeedCg } = props.data as any;
    if (isNeedCg !== true) {
      columnList.splice(3, 1);
    }

    return { columnList };
  },
});
