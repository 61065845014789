
import { computed, Ref, ref } from 'vue';
import Anchor from '@/components/AnchorLink/Anchor.vue';
import AnchorLink from '@/components/AnchorLink/AnchorLink.vue';
import router from '@/router';
import BaseInfo from './components/BaseInfo';
import StandardInfo from './components/StandardInfo.vue';
import ReviewInfo from './components/ReviewInfo.vue';
import ProductInfo from './components/ProductInfo.vue';
import MoreInfo from './components/MoreInfo.vue';
import FundingInfo from './components/FundingInfo.vue';
import DeclareInfo from './components/DeclareInfo.vue';
import { DeclareForm } from './components/types/declareForm';
import { httpGet } from '@/api';
import { errorMessage, getRouterParams } from '@/utils/publicMethods';
export default {
  name: 'declareDetail',
  components: {
    Anchor,
    AnchorLink,
    BaseInfo,
    DeclareInfo,
    FundingInfo,
    MoreInfo,
    ProductInfo,
    ReviewInfo,
    StandardInfo,
  },
  props: {},
  setup() {
    // 获取路由参数
    const { id } = getRouterParams('declareDetail');
    const detailsData: Ref<DeclareForm> = ref(null);
    const load = ref(true);
    const produtNum = computed(() => {
      let str = '';
      let data = detailsData.value;
      if (data?.productVo && data.productVo.productListVos) {
        return `(${data.productVo.productListVos.length})`;
      }
      return str;
    });
    // 初始化数据
    const initData = async () => {
      load.value = false;
      const res = await httpGet(`/malicrm/declare/queryDeclareDetail/${id}`, {});
      if (res.code === 200) {
        detailsData.value = res.result as DeclareForm;
        console.log(detailsData.value);
        load.value = true;
      } else {
        errorMessage(res.message);
      }
    };
    // 返回操作
    const back = () => {
      router.push('/crm/declare/list');
    };
    // 初始化内容
    initData();
    return {
      produtNum,
      detailsData,
      load,
      back,
    };
  },
};
