
import { defineComponent, PropType } from 'vue';
import { DetailColunm } from './types/declareForm';
export default defineComponent({
  props: {
    data: Object as PropType<any>,
  },
  setup() {
    const columnList: DetailColunm[] = [
      {
        label: '抬头',
        prop: 'shortName',
        width: null,
      },
      {
        label: '确认人',
        prop: 'confirmOperator',
        width: null,
      },
      {
        label: '确认时间',
        prop: 'confirmTime',
        width: null,
      },
    ];
    return { columnList };
  },
});
