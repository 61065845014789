
import { computed, defineComponent, nextTick, onMounted, onUnmounted, provide, ref } from 'vue';

export default defineComponent({
  name: 'Anchor',
  props: {
    targetScroll: {
      type: String,
      default: '.erp-body',
    },
    container: {
      type: String,
      default: '.crm-detail__right',
    },
    offset: {
      type: Number,
      default: 60,
    },
  },
  setup(props) {
    const flag: any = ref('');
    const linkArray: any = ref([]);

    const onScroll = (e) => {
      // 距离顶部的值e.path[1].scrollY   e.target.scrollingElement.scrollTop
      // of fsetTop 元素距离父元素上边距离
      let offsetArr: any = [];
      let scrollTop = e.target.scrollTop;
      linkArray.value.forEach((v) => {
        let ele = document.querySelector(v);
        if (ele) {
          offsetArr.push({
            link: v,
            offset: ele.offsetTop,
          });
        }
      });

      let i = -1;
      let len = offsetArr.length;
      let titleItem = {
        link: '#',
        offset: 0,
      };
      while (++i < len) {
        let currentEle = offsetArr[i];
        let nextEle = offsetArr[i + 1];
        if (scrollTop >= currentEle.offset - props.offset && scrollTop < ((nextEle && nextEle.offset - props.offset) || Infinity)) {
          titleItem = offsetArr[i];
          break;
        } else if (scrollTop <= 0) {
          titleItem = offsetArr[0];
          break;
        }
      }
      flag.value = titleItem.link;
    };
    const setDefaultActive = () => {
      // 设置默认第一个激活
      const currentLinkElementA = document.querySelectorAll(`a[data-href^="#"]`);
      currentLinkElementA.forEach((v) => {
        if (v) {
          linkArray.value.push(v.getAttribute('href'));
        }
      });
      let current = currentLinkElementA[0].getAttribute('href');
      if (current) {
        flag.value = currentLinkElementA[0].getAttribute('href');
      }
    };
    provide('anchorLinkActive', {
      targetScroll: computed(() => {
        return props.targetScroll;
      }),
      offset: computed(() => {
        return props.offset;
      }),
      flag: flag,
    });
    onMounted(() => {
      let target = document.querySelector(props.targetScroll);
      if (target) {
        target.addEventListener('scroll', onScroll);
        nextTick(() => {
          setDefaultActive();
        });
      }
    });
    onUnmounted(() => {
      let target = document.querySelector(props.targetScroll);
      if (target) {
        target.removeEventListener('scroll', onScroll);
      }
    });
    return { flag, linkArray, setDefaultActive };
  },
});
